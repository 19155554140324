import locale6010b66b from '../../lang/en-US.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentFallbackWarn":true,"silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","name":"English","iso":"en","file":"en-US.js"},{"code":"de","name":"Deutsch","iso":"de","file":"de-DE.js"},{"code":"fr","name":"français","iso":"fr","file":"fr-FR.js"},{"code":"es","name":"Español","iso":"es","file":"es-ES.js"},{"code":"pt","name":"Português","iso":"pt","file":"pt-PT.js"},{"code":"it","name":"Italiano","iso":"it","file":"it-IT.js"},{"code":"nl","name":"Nederlands","iso":"nl","file":"nl-NL.js"},{"code":"pl","name":"polski","iso":"pl","file":"pl-PL.js"},{"code":"ru","name":"Русский","iso":"ru","file":"ru-RU.js"},{"code":"ro","name":"Română","iso":"ro","file":"ro-RO.js"},{"code":"ko","name":"한국어","iso":"ko","file":"ko-KR.js"},{"code":"zh","name":"中文","iso":"zh","file":"zh-CN.js"},{"code":"ja","name":"日本語","iso":"ja","file":"ja-JP.js"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/root/releases/v2.0.30/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://sneakers123.com",
  vuex: {"moduleName":"i18n","syncLocale":true,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","name":"English","iso":"en","file":"en-US.js"},{"code":"de","name":"Deutsch","iso":"de","file":"de-DE.js"},{"code":"fr","name":"français","iso":"fr","file":"fr-FR.js"},{"code":"es","name":"Español","iso":"es","file":"es-ES.js"},{"code":"pt","name":"Português","iso":"pt","file":"pt-PT.js"},{"code":"it","name":"Italiano","iso":"it","file":"it-IT.js"},{"code":"nl","name":"Nederlands","iso":"nl","file":"nl-NL.js"},{"code":"pl","name":"polski","iso":"pl","file":"pl-PL.js"},{"code":"ru","name":"Русский","iso":"ru","file":"ru-RU.js"},{"code":"ro","name":"Română","iso":"ro","file":"ro-RO.js"},{"code":"ko","name":"한국어","iso":"ko","file":"ko-KR.js"},{"code":"zh","name":"中文","iso":"zh","file":"zh-CN.js"},{"code":"ja","name":"日本語","iso":"ja","file":"ja-JP.js"}],
  localeCodes: ["en","de","fr","es","pt","it","nl","pl","ru","ro","ko","zh","ja"],
}

export const localeMessages = {
  'en-US.js': () => Promise.resolve(locale6010b66b),
  'de-DE.js': () => import('../../lang/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
  'fr-FR.js': () => import('../../lang/fr-FR.js' /* webpackChunkName: "lang-fr-FR.js" */),
  'es-ES.js': () => import('../../lang/es-ES.js' /* webpackChunkName: "lang-es-ES.js" */),
  'pt-PT.js': () => import('../../lang/pt-PT.js' /* webpackChunkName: "lang-pt-PT.js" */),
  'it-IT.js': () => import('../../lang/it-IT.js' /* webpackChunkName: "lang-it-IT.js" */),
  'nl-NL.js': () => import('../../lang/nl-NL.js' /* webpackChunkName: "lang-nl-NL.js" */),
  'pl-PL.js': () => import('../../lang/pl-PL.js' /* webpackChunkName: "lang-pl-PL.js" */),
  'ru-RU.js': () => import('../../lang/ru-RU.js' /* webpackChunkName: "lang-ru-RU.js" */),
  'ro-RO.js': () => import('../../lang/ro-RO.js' /* webpackChunkName: "lang-ro-RO.js" */),
  'ko-KR.js': () => import('../../lang/ko-KR.js' /* webpackChunkName: "lang-ko-KR.js" */),
  'zh-CN.js': () => import('../../lang/zh-CN.js' /* webpackChunkName: "lang-zh-CN.js" */),
  'ja-JP.js': () => import('../../lang/ja-JP.js' /* webpackChunkName: "lang-ja-JP.js" */),
}
